<script lang="jsx">
import { h } from 'vue';

import { closeSidebar } from "@/core/layouts";

export default {
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},

	watch: {
		path() {
			if (this.items.length > 0) {
				this.clearActive(this.items);
				this.setActive(this.items, this.path);
			} else {
				setTimeout(() => {
					this.clearActive(this.items);
					this.setActive(this.items, this.path);
				}, 300)
			}
		}
	},

	computed: {
		path() {
			return this.$route.path
		}
	},

	methods: {
		setActive(items, path) {
			let active = false;

			for (const item of items) {
				item.active = false;

				if (item.route == path) {
					item.active = true;

					active = true;

					break;
				}

				if (item.items && item.items.length > 0) {
					if (this.setActive(item.items, path)) {
						item.active = true;
						active = true;

						break;
					}
				}
			}

			return active;
		},

		clearActive(items) {
			items.forEach(el => {
				if (el.items) {
					this.clearActive(el.items);
				} else {
					el.active = false;
				}
			});
		},

		onClick(e, item) {
			e.preventDefault();

			const { type_menu = null, node = false, params = {} } = item;

			this.clearActive(this.items);

			item.active = item.active ? !item.active : true;

			if (node) {
				switch (type_menu) {
					// case 1:
					case 2:
						const { id } = params;
						this.$router.push(`/panel/${id}`);

						break;
				}
			} else {
				if (!item.items && this.$route.path != item.route) {
					if (item.route) this.$router.push(item.route);

					closeSidebar();
				}
			}
		},

		link(item) {
			const { header, title, icon, route, active = false } = item;

			return h(
				"a",
				{
					class: { "nav-link": true, active },
					title: title ? title : header,
					href: route ? route : "#",
					onClick: e => this.onClick(e, item)
				},
				[
					h("i", { class: icon }),
					h("span", header)
				]
			);
		}
	},

	render() {
		const menuItems = (items) => items.map(item => {
			const { active = false, items = [] } = item;

			const elements = [
				this.link(item)
			];

			if (items.length > 0) {
				elements.push(
					h(
						"ul",
						{
							class: "nav nav-group-sub",
							style: active ? "display: block;" : ""
						},
						menuItems(items)
					)
				);
			}

			return h("li", { class: items.length > 0 ? (active ? "nav-item nav-item-submenu nav-item-open" : "nav-item nav-item-submenu") : "nav-item" }, elements);
		});

		return h("ul", { class: "nav nav-sidebar" }, menuItems(this.items));
	}
};
</script>

<style></style>
